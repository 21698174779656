<template>
  <div :class="wrapperClass">
    <div>{{ displayText }}</div>
    <div class="globalSnackbarMessageClose">
      <IconTooltip
        v-if="isCloseIconVisible"
        icon="fas fa-close close"
        tooltip="Close"
        @click="removeGlobalSnackbarMessage"
      />
    </div>
  </div>
</template>
<script setup>
import { toRefs, computed } from "vue";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";

const props = defineProps({
  id: String,
});
const snackbarStore = useSnackbarStore();
const { messages } = storeToRefs(snackbarStore);
const emit = defineEmits(["onRemoveSnackbarMessage"]);
const { id } = toRefs(props);
const displayText = computed(() => {
  return messages.value[id.value].displayText;
});
const wrapperClass = computed(() => {
  const messageType = messages.value[id.value].messageType;
  return `globalSnackbarMessageWrapper ${messageType}SnackbarMessage`;
});

const isCloseIconVisible = computed(() => {
  return (
    (messages.value[id.value].timeout || 0) > 0 ||
    messages.value[id.value].showCloseButton ||
    false
  );
});

const removeGlobalSnackbarMessage = () => {
  emit("onRemoveSnackbarMessage", id.value);
};
</script>

<style>
.globalSnackbarMessageWrapper {
  display: grid;
  grid-template-columns: 1fr 32px;
  padding: 8px;
  grid-gap: 8px;
  color: #000;
  border-radius: 8px;
  font-size: 15.75px;
  font-weight: 400;
  letter-spacing: 0.315px;
  line-height: 22.5px;
}

.globalSnackbarMessageClose {
  display: grid;
  justify-content: end;
  align-items: start;
  font-size: 20px;
}

.successSnackbarMessage {
  background-color: rgb(2, 153, 73);
  border: solid 1px #2d640e;
  color: #fff;
}

.warningSnackbarMessage {
  background-color: rgb(224, 171, 6);
  border: solid 1px #8b6c0d;
}

.errorSnackbarMessage {
  background-color: rgb(215, 26, 23);
  border: solid 1px #840d09;
  color: #fff;
}

.error_customSnackbarMessage {
  background-color: #ff0000;
  border: solid 1px #840d09;
  color: #000;
}
</style>
