<template>
  <v-overlay v-model="isRouteChanged" persistent />
  <div class="pageLoaderContainer">
    <v-progress-linear
      v-show="isRouteChanged"
      indeterminate
      color="primary"
      class="mb-0"
    />
  </div>
</template>


<script setup>
  import { useAppViewStateStore } from '@/stores/appViewState';
  import { storeToRefs } from 'pinia';

  const appViewStateStore = useAppViewStateStore();
  const { isRouteChanged } = storeToRefs(appViewStateStore);
</script>

<style>
  .pageLoaderContainer {
    position: fixed;
    height: 5px;
    width: 100vw;
    left: 0;
    top: 0;
    margin: auto;
    z-index: 1006;
  }
</style>
