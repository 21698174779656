<template>
  <v-snackbar
    v-model="isSnackBarVisible"
    position="sticky"
    location="top"
    multi-line
    :timeout="-1"
    rounded="sm"
    color="#fff"
  >
    <div class="globalSnackbarWrapper">
      <TimeoutPopup v-if="isAuthenticated && isSnackBarVisible" />
      <div v-for="message in messageList" v-bind:key="message.id">
        <SnackbarMessage
          :message="message"
          @onRemoveSnackbarMessage="snackbarStore.removeMessage"
        />
      </div>
    </div>
  </v-snackbar>
</template>

<script setup>
import SnackbarMessage from "@/components/globalSnackbar/SnackbarMessage.vue";
import TimeoutPopup from "@/components/timeout/TimeoutPopup.vue";
import { watch, ref, computed, onMounted, onUnmounted } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/userStore";
import { useAppTimeoutStore } from "@/stores/appTimeOuts";
import { storeToRefs } from "pinia";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

const snackbarStore = useSnackbarStore();
const appTimeoutStore = useAppTimeoutStore();
const userStore = useUserStore();
const { messages } = storeToRefs(snackbarStore);
const { showTimeoutPopup } = storeToRefs(appTimeoutStore);
const { onAppMounted, onAppUnmounted } = appTimeoutStore;
const isSnackBarVisible = ref(false);
const messageList = ref([]);
let isAuthenticated = computed(() => userStore.isAuthenticated);
const update = () => {
  isSnackBarVisible.value =
    Object.keys(messages.value).length > 0 || showTimeoutPopup.value;
  const errors = [];
  const warnings = [];
  const successes = [];
  for (const id in messages.value) {
    const message = messages.value[id];
    if (message.messageType === SNACKBAR_MESSAGE_TYPES.ERROR) {
      errors.push(message);
    } else if (message.messageType === SNACKBAR_MESSAGE_TYPES.WARNING) {
      warnings.push(message);
    } else if (message.messageType === SNACKBAR_MESSAGE_TYPES.SUCCESS) {
      successes.push(message);
    }
  }
  messageList.value = [...errors, ...warnings, ...successes];
};
onMounted(onAppMounted);
onUnmounted(onAppUnmounted);
update();
watch(
  () => [messages, showTimeoutPopup],
  () => {
    update();
  },
  { deep: true }
);
</script>

<style>
.globalSnackbarWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  max-height: 200px;
  overflow-y: auto;
}

.timeoutPopup {
  background-color: rgb(224, 171, 6);
  border: solid 1px #8b6c0d;
}
</style>
