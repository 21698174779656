<template>
  <div v-if="showTimeoutPopup" class="timeoutPopup">
    <p>{{ timeLeft }}</p>
    <div v-if="showButtons" style="margin: 8px 0">
      <v-divider thickness="1"></v-divider>
    </div>
    <div v-if="showButtons" class="onlineActionButtons">
      <v-btn @click="setOfflineEvent" variant="text"> Yes </v-btn>
      <v-btn @click="setOnlineEvent" variant="text"> No </v-btn>
    </div>
  </div>
</template>
<script setup>
import { useAppTimeoutStore } from "@/stores/appTimeOuts";
import { storeToRefs } from "pinia";
const appTimeoutStore = useAppTimeoutStore();
const { timeLeft, showTimeoutPopup, showButtons } =
  storeToRefs(appTimeoutStore);
const { setOfflineEvent, setOnlineEvent } = appTimeoutStore;
</script>
<style>
.timeoutPopup {
  display: grid;
  grid-template-columns: 1fr;
  padding: 8px;
  color: #000;
  border-radius: 8px;
  font-size: 15.75px;
  font-weight: 400;
  letter-spacing: 0.315px;
  line-height: 22.5px;
  background-color: rgb(224, 171, 6);
  border: solid 1px #8b6c0d;
}

.onlineActionButtons {
  display: grid;
  justify-content: flex-end;
  grid-auto-flow: column;
  grid-gap: 16px;
}
</style>
