<template>
  <v-dialog v-model="showPasswordSetupValue">
    <v-card class="card_align-center">
      <v-toolbar-title>
        <div class="d-flex header-logo">
          <img :src="logo" alt="PA Keystone Logo" class="h3 lang-logo" />
          <div class="ml-2">Offline Password Setup</div>
          <button
            id="btn_stayAndCloseX"
            @click="handleCancel"
            class="d-flex align-end flex-column align-content-center close-button"
          >
            <v-icon size="x-large" icon="fas fa-xmark mx-2" />
          </button>
        </div>
      </v-toolbar-title>
      <v-card-text>
        <v-container>
          <v-form ref="passwordForm" v-model="valid">
            <v-row>
              <v-col cols="12">
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="5"
                  labelSm="5"
                  textXl="5"
                  textLg="5"
                  textMd="5"
                  textSm="5"
                  id="offline_userID"
                  label="UserID"
                  :isEditable="false"
                  v-model="userName"
                >
                </LabelText>
              </v-col>
              <v-col cols="12">
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="5"
                  labelSm="5"
                  textXl="5"
                  textLg="5"
                  textMd="5"
                  textSm="5"
                  id="offline_password"
                  :isEditable="true"
                  :showExclamationIcon="!requiredPassword"
                  :showGreenCheckIcon="requiredPassword"
                  appendInnerIcon
                  label="Enter Password"
                  v-model="password"
                  @update:modelValue="validateForm()"
                  type="text"
                  :minlength="8"
                  password
                  :rules="[
                    requiredRule,
                    (value) => {
                      return valueLengthInRangeRule(value, 8);
                    },
                    (value) => {
                      return valueCannotBeRule(value, '00000000');
                    },
                  ]"
                >
                </LabelText>
              </v-col>
              <v-col cols="12">
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="5"
                  labelSm="5"
                  textXl="5"
                  textLg="5"
                  textMd="5"
                  textSm="5"
                  id="offline_confirm_password"
                  :isEditable="true"
                  label="Confirm Password"
                  v-model="confirmPassword"
                  type="text"
                  :showExclamationIcon="!passwordCheck"
                  :showGreenCheckIcon="passwordCheck"
                  @update:modelValue="validateForm()"
                  appendInnerIcon
                  :minlength="8"
                  password
                  :rules="[
                    requiredRule,
                    (value) => {
                      return confirmPasswordsMatch(value);
                    },
                    (value) => {
                      return valueLengthInRangeRule(value, 8);
                    },
                    (value) => {
                      return valueCannotBeRule(value, '00000000');
                    },
                  ]"
                ></LabelText>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <div class="warning-text">
          {{ INSPECTION_MESSAGES.OFFLINE_PASSWORD_WARNING_MESSAGE }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="btn_login"
          variant="outlined"
          class="mx-5"
          :disabled="!passwordCheck"
          @click="handleSubmit"
        >
          Ok
        </v-btn>
        <v-btn
          id="btn_cancel"
          variant="outlined"
          class="mx-5"
          @click="handleCancel"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LabelText from "@/components/common/LabelText.vue";
import logo from "@/assets/PennDOT-keystone-logo.svg";
import { ref, toRefs, defineComponent, computed, watch } from "vue";
import {
  requiredRule,
  valueLengthInRangeRule,
  valueCannotBeRule,
} from "@/composables/validationRules";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useUserStore } from "@/stores/userStore";
import { useAdminStore } from "@/stores/admin";
import sha256 from "crypto-js/sha256";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import { useSnackbarStore } from "@/stores/snackbar";
import { SNACKBAR_MESSAGE_TYPES } from "@/constants/GlobalSnackbar.js";

export default defineComponent({
  name: "OfflinePasswordReset",
  emits: ["hidePasswordSetup"],
  props: ["showPasswordSetup"],

  setup(props, ctx) {
    const { showPasswordSetup } = toRefs(props);
    let showPasswordSetupValue = ref(showPasswordSetup.value);
    const userStore = useUserStore();
    const snackbarStore = useSnackbarStore();
    const userName = computed(() => userStore.loggedInUser?.sub);
    let password = ref(null);
    let confirmPassword = ref(null);
    let passwordForm = ref(null);
    let valid = ref(false);
    let passwordCheck = ref(false);
    let requiredPassword = ref(false);
    let snackBarId = ref("");
    const handleCancel = () => {
      reset();
      showPasswordSetupValue.value = false;
      ctx.emit("hidePasswordSetup", true);
    };

    const handleSubmit = () => {
      const adminStore = useAdminStore();
      snackbarStore.removeMessage(snackBarId.value);
      passwordForm.value?.validate().then(async () => {
        if (valid.value) {
          const hashedPass = sha256(password.value).toString();
          if (!passwordCheck.value) {
            reset();
            return;
          }
          await adminStore.saveUser({
            userId: userName.value,
            passwordHash: hashedPass,
          });
          const structureIDBStore = useStructureIDBStore();
          await structureIDBStore.initDB(false);
          await structureIDBStore.updatePassword(hashedPass);
          snackBarId.value = snackbarStore.showMessage({
            displayText: "Password changed Successfully",
            timeout: 5000,
            messageType: SNACKBAR_MESSAGE_TYPES.SUCCESS,
          });
          adminStore.setPasswordSet(true);
          reset();
          showPasswordSetupValue.value = false;
          ctx.emit("hidePasswordSetup", true);
        }
      });
    };
    const confirmPasswordsMatch = (value) => {
      if (value !== password.value) {
        return `Password mismatch`;
      } else {
        return true;
      }
    };

    const validateForm = () => {
      passwordForm.value.validate();
    };

    const reset = () => {
      password.value = null;
      confirmPassword.value = null;
    };
    watch(showPasswordSetup, (newValue) => {
      showPasswordSetupValue.value = newValue;
    });

    watch(
      () => [confirmPassword.value, password.value],
      () => {
        passwordCheck.value =
          password.value === confirmPassword.value &&
          password.value &&
          password.value != "00000000" &&
          password.value.length >= 8;
      }
    );

    watch(
      () => [password.value],
      () => {
        requiredPassword.value =
          password.value &&
          password.value != "00000000" &&
          password.value.length >= 8;
      }
    );
    return {
      validateForm,
      showPasswordSetupValue,
      userName,
      password,
      confirmPassword,
      passwordForm,
      valid,
      handleSubmit,
      handleCancel,
      requiredRule,
      valueLengthInRangeRule,
      valueCannotBeRule,
      watch,
      reset,
      logo,
      LabelText,
      confirmPasswordsMatch,
      passwordCheck,
      requiredPassword,
      INSPECTION_MESSAGES,
    };
  },
  components: {
    LabelText,
  },
});
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.important-text {
  color: c.$p-alert-red;
}
.card_align-center {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.v-dialog > .v-overlay__content > .v-card > .v-card-text {
  padding: 40px 0px 40px;
}
.v-container {
  padding: 0px;
}
</style>
